
import * as vercelRuntime$b8uWPRx1qM from '/vercel/path0/node_modules/@nuxt/image/dist/runtime/providers/vercel'

export const imageOptions = {
  "screens": {
    "xs": 256,
    "sm": 256,
    "md": 256,
    "lg": 256,
    "xl": 256,
    "xxl": 256,
    "2xl": 256
  },
  "presets": {},
  "provider": "vercel",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['vercel']: { provider: vercelRuntime$b8uWPRx1qM, defaults: {} }
}
        